import CSTemplate from './coming-soon-template.js';

// Try to send email when form submits
const form = document.querySelector('#connect-form');
form && form.addEventListener("submit", sendEmail);

function sendEmail(event) {
  event.preventDefault();

  // Get the email element and set innerText to it
  const name = document.querySelector('#name');
  const email = document.querySelector('#email');
  const info = document.querySelector('#info')
  const successMsg = document.querySelector('#success-msg');
  let emailTemplate = document.createElement('div');
  emailTemplate.innerHTML = CSTemplate;
  emailTemplate.querySelector('#user-name').innerText = name.value;
  emailTemplate.querySelector('#user-email').innerText = email.value;
  emailTemplate.querySelector('#user-info').innerText = info.value;

  fetch('https://gw-prod.utilbox.io/utilbox/email/send', {
  //fetch('http://localhost:8080/utilbox/email/send', {
    mode: 'cors',
  method: 'POST',
  headers: { 'Content-type': 'application/json' , 'Authorization': 'SY3ZzPO0EhjiiqhXAL2GVFSCixzLuFXTxWpHSQG2WzBeWr9HlW', 'Access-Control-Allow-Origin':'*','Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS' },
  body: JSON.stringify({ 'name': name.value, 'body': info.value, 'email': email.value })
  })
  .then(() => {
    name.value = '';
    email.value = '';
    info.value = '';
    successMsg.style.display = 'block';
  });

  console.log("Debug log ====> "+JSON.stringify({ "name": name.value, "body": info.value, "email": email.value }))


  // All set, send email now
  Email.send({
    SecureToken: "b7f618ab-150c-48f3-82c3-df5bc07651cd",
    To: ['connect@appgolive.com'],
    From: "prateeklal@gmx.com",
    FromName: "AppGoLive Query",
    Subject: "New query on AppGoLive",
    Body: emailTemplate.outerHTML
  }).then(() => {
    name.value = '';
    email.value = '';
    info.value = '';
    successMsg.style.display = 'block';
  }
  );
}

/***********************************
 * Mobile menu toggle functionality
***********************************/

const mobMenuBtn = document.getElementById('mobile-menu');
const headerElem = document.getElementById('header');
const menuElems = document.getElementsByClassName('menu-item');

// Display mobile menu when hamburger menu is clicked
mobMenuBtn.addEventListener('click', toggleMenu);

// Hide menu and scroll to section when user clicks on a menu item.
// Convert HTMLCollection to array and add event listener to all menu items.
Array.from(menuElems).forEach(item => item.addEventListener('click', toggleMenu));

window.addEventListener('resize', toggleHeaderClass);
window.addEventListener('load', toggleHeaderClass);

function toggleMenu() {
  headerElem.classList.toggle('active');
}
// Add a class to header when window width is less than 641px.
// This is done to avoid the fade out transition when user resets their screen size from desktop to mobile.
function toggleHeaderClass(event) {
  if (event.currentTarget.innerWidth < 641) {
    headerElem.classList.contains('header-mobile') ? '' : headerElem.classList.add('header-mobile');
  } else {
    headerElem.classList.contains('header-mobile') ? headerElem.classList.remove('header-mobile') : '';
  }
}