const template = `
<div
  style="
    font-family: 'Open Sans', Roboto, 'Helvetica Neue', Helvetica, Arial,
      sans-serif;
    font-size: 16px;
    color: #757575;
    line-height: 150%;
    letter-spacing: normal;
  "
>
  <div style="background-color: #006880; padding: 80px 10px;">
    <div style="max-width: 600px; margin: auto;">
      <div
        style="
          background: #fff;
          padding: 15px 30px 25px 30px;
          border-radius: 5px;
        "
      >
        <p>Hi there,</p>
        <p>
          You have received a new query from the following -
        </p>
        <hr/>
        <p>
          Name:
          <span style="margin-left: 5px; color: #006880;" id="user-name"
            ></span
          >
        </p>
        <p>
          Email:
          <span style="margin-left: 5px; color: #006880;" id="user-email"
            ></span
          >
        </p>
        <p>
        Short notes:
        <span style="margin-left: 5px; color: #006880;" id="user-info"
          ></span
        >
      </p>
        <hr />
        <br />
        <p>If you didn't request it - simply ignore this email.</p>
        <p style="margin-top: 40px;">Best regards,<br />AppGoLive Team</p>
      </div>
    </div>
  </div>
  <div
    style="
      background-color: #c3eae2;
      color: #44255e;
      font-size: 12px;
      padding: 20px 10px 20px 10px;
    "
  >
    <div style="max-width: 600px; margin: auto; text-align: center;">
      <p style="margin-bottom: 0; line-height: 2.8;">
        Copyright © 2020 AppGoLive, All rights reserved.
      </p>
      <p style="line-height: 1;">
        You are receiving this email because you have subscribed to receive
        information on AppGoLive launch updates.
      </p>
      <p style="line-height: 1.5;">
        If you want to unsubscribe, please contact the support team at
        connect@appgolive.com
      </p>
    </div>
  </div>
</div>

`;

export default template;